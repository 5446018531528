import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';
export class PersonAccountEntityModel extends BaseEntityModel {
    @FormControl({
        label: '登录密码',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    password: string = undefined;

    @FormControl({
        label: '邮箱账号',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    email: string = undefined;
}
