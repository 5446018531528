var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "person-index-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [_c("a-breadcrumb-item", [_vm._v("个人中心")])],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "a-layout",
          [
            _c(
              "a-layout-sider",
              {
                staticClass: "page-menu-sider personcenter-menu",
                attrs: { theme: "light" },
              },
              [
                _c(
                  "div",
                  { staticClass: "personcenter-avatar" },
                  [
                    _c(
                      "a-avatar",
                      { staticClass: "avatar-font", attrs: { name: "icon" } },
                      [
                        _c("a-icon", {
                          attrs: { slot: "icon", type: "user" },
                          slot: "icon",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("a-list", {
                  attrs: {
                    "item-layout": "horizontal",
                    "data-source": _vm.PersonMenu,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "renderItem",
                      fn: function (item) {
                        return _c(
                          "a-list-item",
                          {},
                          [
                            _c("a-list-item-meta", [
                              _c(
                                "a",
                                {
                                  attrs: { slot: "title" },
                                  on: {
                                    click: function ($event) {
                                      _vm.currentMenuType = item.type
                                    },
                                  },
                                  slot: "title",
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                            ]),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("a-layout-content", [
              _c(
                "div",
                { staticClass: "page-body-margin-left-16" },
                [
                  _c("person-info-component", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentMenuType === 1,
                        expression: "currentMenuType===1",
                      },
                    ],
                  }),
                  _c("person-security-component", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentMenuType === 3,
                        expression: "currentMenuType===3",
                      },
                    ],
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }