var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "person-security-component" },
    [
      _c(
        "div",
        [
          _c("a-card", {
            staticClass: "table-query-card",
            attrs: { title: "账号安全" },
          }),
          _c("div", [
            _c(
              "div",
              { staticClass: "person-account" },
              [
                _c("span", { staticClass: "text" }, [_vm._v("账号密码：")]),
                _c("span", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.personAccountEntityModel.password)),
                ]),
                _c(
                  "a-button",
                  {
                    attrs: { type: "link" },
                    on: {
                      click: function ($event) {
                        return _vm.newPasswordClick(
                          _vm.personAccountEntityModel.id
                        )
                      },
                    },
                  },
                  [_vm._v(" 修改 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("user-pwd-dialog", {
        ref: "userPwdFormDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }